/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import DDAdminNavbarLinks from "./DDAdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";
import { UserContext } from "App";

import styles from "assets/jss/material-dashboard-pro-react/components/dd_adminNavbarStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

export default function DDAdminNavbar(props) {
    const classes = useStyles();
    const { color, rtlActive, brandText } = props;
    const {
        companyName,
        setCompanyName,
        userFullName,
        setUserFullName,
        logo,
        setLogo,
        locations,
        selectedLocation,
        setSelectedLocation,
    } = useContext(UserContext);

    const createLocationOptions = (locations) => {
        return (
            locations?.map((loc, key) => {
                return (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                        }}
                        value={loc[0]}
                        selected={key === 0}
                    >
                        {loc[1]}
                    </MenuItem>
                );
            }) || <></>
        );
    };

    const wrapLocationOptions = () => {
        return locations?.length > 1 ? (
            <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                >
                    Choose Client
                </InputLabel>
                <Select
                    MenuProps={{
                        className: classes.selectMenu,
                    }}
                    classes={{
                        select: classes.select,
                    }}
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                    }}
                >
                    {createLocationOptions(locations)}
                </Select>
            </FormControl>
        ) : (
            <></>
        );
    };

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const appBarClasses = cx({
        [" " + classes[color]]: color,
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        " " +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive,
        });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <MoreVert className={classes.sidebarMiniIcon} />
                            </Button>
                        )}
                    </div>
                </Hidden>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={9} lg={9} xl={9}>
                        {/* Here we create navbar brand, based on route name */}

                        <Box pl={2}>
                            <div className={classes.typo}>
                                {logo ? (
                                    <img
                                        src={logo}
                                        className={classes.clientLogo}
                                    />
                                ) : (
                                    <h3>{companyName}</h3>
                                )}
                            </div>
                        </Box>
                        <br />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
                        {wrapLocationOptions()}
                    </GridItem>
                </GridContainer>
                <Hidden smDown implementation="css">
                    <DDAdminNavbarLinks rtlActive={rtlActive} />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

DDAdminNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    brandText: PropTypes.string,
    miniActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func,
};
