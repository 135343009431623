import React from "react";
import { useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./utils/protected-route";
import { useAuth0 } from "@auth0/auth0-react";

import DDDashLayout from "layouts/DDDashLayout.js";
import Loading from "components/loading";
import DDDashboard from "views/Dashboard/DDDashboard.js";
import DashboardIcon from "@material-ui/icons/Dashboard";

import { addAccessTokenInterceptor, Api } from "./Api";

const UserContext = React.createContext(null);

const App = () => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { isLoading } = useAuth0();
    const [routes, setRoutes] = useState([]);
    const [firstPath, setFirstPath] = useState();
    const [companyName, setCompanyName] = useState();
    const [userFullName, setUserFullName] = useState();
    const [logo, setLogo] = useState();
    const [locations, setLocations] = useState();
    const [selectedLocation, setSelectedLocation] = useState("default");

    React.useEffect(() => {
        addAccessTokenInterceptor(getAccessTokenSilently, loginWithRedirect);
    }, [getAccessTokenSilently]);

    const getVisuals = async () => {
        await Api.get("/dash/vlist/" + selectedLocation).then((res) => {
            let visuals = res.data.visuals;
            let company_name = res.data.cname;
            let user_full_name = res.data.ufname;
            let route_list = [];
            let first_visual = null;
            let logo = res.data.logo;

            // eslint-disable-next-line no-unused-vars
            visuals.items.map((v, key) => {
                console.log(">> v:", v);
                if (v.type === "visual") {
                    let rou = {
                        path: v.path,
                        name: v.title,
                        icon: DashboardIcon,
                        component: DDDashboard,
                        layout: "/dd",
                        hashed_id: v.hashed_id,
                    };
                    route_list.push(rou);
                    if (first_visual === null) {
                        first_visual = v;
                    }
                }
            });
            console.log(">> route_list:", route_list);
            setRoutes(route_list);
            if (first_visual != null) {
                setFirstPath("/dd" + first_visual.path);
            }
            setCompanyName(company_name);
            setUserFullName(user_full_name);
            setLogo(logo);
        });
    };

    React.useEffect(async () => {
        document.title = "DWDigest";
        await Api.get("/dash/locs").then((res) => {
            let locations = res.data.locs;
            if (locations.length > 0) {
                setSelectedLocation(locations[0][0]);
            }
            if (locations.length > 1) {
                setLocations(locations);
            }
        });
    }, []);

    React.useEffect(async () => {
        getVisuals();
    }, [selectedLocation]);

    const getRoutes = (routes) => {
        let result = routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/dd") {
                return (
                    <ProtectedRoute
                        path={prop.layout + prop.path}
                        component={DDDashLayout}
                        routes={routes}
                        hashed_id={prop.hashed_id}
                        /* render={(properties) => <DDDashLayout {...properties} routes={routes} location={window.location} />} */
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
        return result;
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div id="app" className="d-flex flex-column h-100">
            <div className="container flex-grow-1">
                <UserContext.Provider
                    value={{
                        companyName,
                        setCompanyName,
                        userFullName,
                        setUserFullName,
                        logo,
                        setLogo,
                        locations,
                        selectedLocation,
                        setSelectedLocation,
                    }}
                >
                    <Switch>
                        {getRoutes(routes)}
                        {firstPath != null ? (
                            <Redirect from="/" to={firstPath} />
                        ) : (
                            <Loading />
                        )}
                    </Switch>
                </UserContext.Provider>
            </div>
        </div>
    );
};

export { App, UserContext };
