/* eslint-disable no-unused-vars */
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const Api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_SERVER_URL,
    responseType: "json",
    withCredentials: true,
});

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (
    getAccessTokenSilently,
    loginWithRedirect
) => {
    // Get access token from auth0 sdk
    Api.interceptors.request.use(async (config) => {
        const token = await getAccessTokenSilently({scope:"offline_access"});
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    });

    Api.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error.
            // Generically handle authorization error from our backend, and redirect to login page
            if (
                (error.response != undefined && error.response.status == 401) ||
                (error.error != undefined && error.error === "login_required")
                // auth0 does not set response code, but set the error.error = 'login_required'
            ) {
                loginWithRedirect();
            }
            return Promise.reject(error);
        }
    );
};

/* eslint-disable no-unused-vars */

export default Api;
