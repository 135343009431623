// src/auth/protected-route.js

import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "../components/index";
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component, routes, hashed_id, ...args }) => {
  const Child = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });
  console.log("<><><><><> ProtectedRoute ...args:", { ...args });

  return (
    < Route

      render={(properties) => <Child {...properties} routes={routes} hashed_id={hashed_id} location={window.location} {...args} />}
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  routes: PropTypes.any,
  hashed_id: PropTypes.any
};

export default ProtectedRoute;
