import React from "react";
import cx from "classnames";
import { Switch, Route } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import DDAdminNavbar from "components/Navbars/DDAdminNavbar.js";
import DDSidebar from "components/Sidebar/DDSidebar.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import DDFooter from "components/Footer/DDFooter";

import { useState, useContext } from "react";
import { UserContext } from "App";

var ps;

const useStyles = makeStyles(styles);

const localStyle = (theme) => ({
    brspacer: {
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
});

const useStylesLocal = makeStyles(localStyle);

/* eslint react/prop-types: 0 */
export default function Dashboard(props) {
    const { ...rest } = props;
    const { locations } = useContext(UserContext);
    /* eslint-disable no-unused-vars */

    // states and functions
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [miniActive, setMiniActive] = React.useState(false);
    const [image, setImage] = React.useState(
        require("assets/DD/img/map6.jpg").default
    );
    const [color, setColor] = React.useState("nc_orange");
    const [bgColor, setBgColor] = React.useState("black");

    const [ddLogo, setDdLogo] = React.useState(
        require("assets/DD/img/nextCoder_just_logo.jpg").default
    );

    // styles
    const classes = useStyles();
    const localClasses = useStylesLocal();
    const mainPanelClasses =
        classes.mainPanel +
        " " +
        cx({
            [classes.mainPanelSidebarMini]: miniActive,
            [classes.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1,
        });
    // ref for main panel div
    const mainPanel = React.createRef();
    // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount

    const [url, setUrl] = useState();

    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);

        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    });

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const getActiveRoute = (routes) => {
        let activeRoute = "Default Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getRoutes = (routes, url) => {
        console.log(">> DDDashboardLayout.getRoutes: routes:", routes);
        return routes.map((prop, key) => {
            console.log(">> DDDashboardLayout.getRoutes: prop:", prop);
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/dd") {
                const TheComponent = prop.component;
                console.log(
                    ">> DDDashboardLayout.getRoutes: prop.component:",
                    prop.component
                );
                console.log(
                    ">> DDDashboardLayout.getRoutes: prop.hashed_id:",
                    prop.hashed_id
                );
                return (
                    <Route
                        path={prop.layout + prop.path}
                        render={(properties) => (
                            <TheComponent
                                {...properties}
                                hashed_id={prop.hashed_id}
                            />
                        )}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const sidebarMinimize = () => {
        setMiniActive(!miniActive);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    return (
        <div className={classes.wrapper}>
            <DDSidebar
                routes={props.routes}
                logoText={"DWDigest"}
                ddLogo={ddLogo}
                image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                bgColor={bgColor}
                miniActive={miniActive}
                {...rest}
            />
            <div
                className={mainPanelClasses}
                ref={mainPanel}
                /* KG - to remove scrollbar around the dashboard */
                style={{ overflow: "visible" }}
            >
                <DDAdminNavbar
                    sidebarMinimize={sidebarMinimize.bind(this)}
                    miniActive={miniActive}
                    brandText={getActiveRoute(props.routes)}
                    handleDrawerToggle={handleDrawerToggle}
                    {...rest}
                />
                {
                    <>
                        {locations?.length > 1 ? (
                            <>
                                <br className={localClasses.brspacer} />
                                <br className={localClasses.brspacer} />
                                <br className={localClasses.brspacer} />
                            </>
                        ) : (
                            <></>
                        )}
                        <div
                            className={classes.content}
                            style={{ height: "100%" }}
                        >
                            <div
                                className={classes.container}
                                style={{ height: "3000px" }}
                            >
                                <Switch>{getRoutes(props.routes)}</Switch>
                            </div>
                        </div>
                    </>
                }
                <DDFooter />
            </div>
        </div>
    );
}
