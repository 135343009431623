/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { addAccessTokenInterceptor, Api } from "../../Api";
import Loading from "components/loading";
import PropTypes from "prop-types";

export default function DDDashboard(props) {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [url, setUrl] = useState("");

    useEffect(() => {
        addAccessTokenInterceptor(getAccessTokenSilently, loginWithRedirect);
    }, [getAccessTokenSilently]);

    useEffect(async () => {
        await Api.get("/dash/sgn_u", {
            params: { hashed_id: props.hashed_id },
        }).then((res) => {
            setUrl(res.data.url);
        });
    }, []);

    return (
        <div style={{ height: "100%" }}>
            {url === "" ? (
                <Loading />
            ) : (
                <iframe src={url} width="100%" height="100%" frameBorder="0" />
            )}
        </div>
    );
}

DDDashboard.propTypes = {
    hashed_id: PropTypes.string,
};
/* eslint-disable no-unused-vars */
